var partnerAds = partnerAds || {};

partnerAds.cvrlookup = partnerAds.cvrlookup || function () {
   var counter = 0;

   function init() {
      if ($('.signup__form').length > 0) {
        var input = $('#cvrlookup-input');

        input.blur(function(){
            if ($(this).val().length > 7) {
                lookup($(this).val());
            }
        });
      }
   }

   function lookup(vat) {
       $.ajax({
           type: "GET",
           dataType: "jsonp",
           url: "//cvrapi.dk/api?search=" + vat + "&country=dk",
           success: function (data) {
               // Owners will be an array, so have to check the array first.
               if (data.owners !== null) {
                   setField($('#form-owner-name'), data.owners[0].name);
               }
               // If it is null, add the invalid class
               else {
                   $('#form-owner-name').parent('.form-group').addClass('label-effect invalid');
                   $('#form-owner-name').prev('label').addClass('label-show');
               }
               setField($('#form-company-name'), data.name);
               setField($('#form-company-address'), data.address);
               setField($('#form-company-zipcode'), data.zipcode);
               setField($('#form-company-city'), data.city);
               setField($('#form-company-phone'), data.phone);
               setField($('#form-company-email'), data.email);
           }
       });
   }

   function setField(input, dataValue) {
       var inputFormGroup = input.parent('.signup__form'),
            validate = input.parent('.validation-input'),
           label = input.prev('label');

       if (dataValue !== null) {
           // Increment the counter with a 100 (its used for ms)
           counter += 100;

           setTimeout(function(){
               // Set value
               input.val(dataValue);

               // Handle invalid/valid classes
               if (inputFormGroup.hasClass('invalid')) {
                   inputFormGroup.removeClass('invalid');
               }
               inputFormGroup.addClass('valid');

               // Fake animate
               label.addClass("active");
               validate.addClass("correct");
           }, counter);
       }
       else {
           inputFormGroup.addClass('invalid');

       }
   }

   return {
        init: init
   }

}();