"use strict"

var partnerAds = partnerAds || {};

partnerAds.filterList = partnerAds.filterList || function () {

   function init() {
      // Init functions
      if ($('.category-table').length > 0) {
         filterList();
      }
   }

   function filterList() {
      // Creating the list sort/filter list
      var options = {
        valueNames: [ 'sortName', 'sortSegment', 'sortAmount', 'sortPercent' ]
      };

      var userList = new List('list-filter', options);

      // Sort list ASC on load
      $(document).ready(function() {
          userList.sort('sortName', { order: 'asc' });
      });

      // List item counting
      userList.on('updated', function() {
          var countUpdate = $(".list li").length;
          var countFullList = userList.size();
          // Show current list size
          $('#current-list-size').html(countUpdate);
          // Show full list size
          $('#full-list-size').html(countFullList);
      });

      // Sort the list when value from dropdown is clicked
      $('.sort-dropdown').on('change', function() {
          var value = $(this).val();
          $('.filter-buttons__button').prop('checked', false);
          if ( value == "sortName" ) {
              userList.sort('sortName', { order: 'asc' });
          } else if ( value == "sortSegment" ) {
              userList.sort('sortSegment', { order: 'asc' });
          } else if ( value == "sortAmount-lh" ) {
              userList.sort('sortAmount', { order: 'asc' });
          } else if ( value == "sortAmount-hl" ) {
              userList.sort('sortAmount', { order: 'desc' });
          } else if ( value == "sortPercent-lh" ) {
              userList.sort('sortPercent', { order: 'asc' });
          } else if ( value == "sortPercent-hl" ) {
              userList.sort('sortPercent', { order: 'desc' });
          }
      });

      $('.filter-buttons__button').on('change', function() {
          var selection = $(this).val();

          $('.sort-dropdown').prop('selectedIndex',0);
          if ( selection == "sortPercent" ) {
              userList.sort('sortPercent', { order: 'desc' });
          } else if ( selection == "sortAmount" ) {
              userList.sort('sortAmount', { order: 'desc' });
          }
      });
   }


   return {
        init: init
   }

}();