"use strict"

var partnerAds = partnerAds || {};

partnerAds.validate = partnerAds.validate || function () {

   function init() {
      // Init functions
      if ($('.signup__form').length > 0) {
         inputValidation();
         addExtraWebsite();
      }
   }

   function inputValidation() {
         //    // Show/hide label on focusin/focusout
         $(".validation-input input, .validation-input textarea").focusin(function () {
            $(this).siblings("label").addClass("active");
         });

         $(".validation-input input, .validation-input textarea").focusout(function () {
            var $this = $(this);
            $this.siblings("label").removeClass("active");

            // Check if input has no validation required before validating
            if (!$this.hasClass("no-validation")) {
               // Validate input fields with validation required
               if (!$this.hasClass("validated") && $this.val() != '')
                  $this.addClass("validated");
                  validateInput($this);
               }
         });

         $(".validation-input input, .validation-input textarea, .validation-input input:checkbox").bind('input propertychange change', function () {
            validateInput($(this));
         });

         $('.validation-select').on('change', function() {
            if ($(this).find('select').children('option').not(':first-child').is(':selected')) {
               $(this).addClass('correct');
               $(this).removeClass('wrong');
               $(this).find('.wrong-span').remove();
            } else {
               $(this).removeClass('correct');
            }
         });

         $('.signup__form').on('submit', function() {
            var $correctInputs = $('.input-required.correct');
            var $requiredInputs = $('.input-required');

            $('.form-control').each(function() {
               if ($(this).is(':checkbox')) {
                  if ($(this).is(':checked')) {
                     $(this).parent().removeClass('wrong');
                     $(this).parent().addClass('correct');
                  } else {
                     $(this).parent().removeClass('correct');
                     $(this).parent().addClass('wrong');
                  }
               }
            });
            
            // If all required input fields are set to correct, submit form.
            if ($correctInputs.length >= $requiredInputs.length) {

               return true;

            // Else run the validation and scroll to first wrong input
            } else {
               console.log($correctInputs.length);
               console.log($requiredInputs.length);
               selector = $('.signup__form');
               validator = $('.input-required', selector),
               validatorValidated = $('.input-required.correct', selector);
               validator.each(function() {
                  var inputNotValidated = $('input, textarea, select', $(this)).addClass('validated');
                  // Validate empty/wrong fields
                  validateInput(inputNotValidated);
               });

               var $firstUnvalidatedInput = $('.input-required.wrong').first(); 
               $("body, html").animate({
                   scrollTop: $firstUnvalidatedInput.offset().top - 20
               }, 500, 'swing');

               return false;
            }
         });     
      }

      function addExtraWebsite() {
         $('.signup__add-extra-website-btn').on('click', function() {
            var $addWebsite = $(this).parent().next().clone();
            $addWebsite.find('.validation-input').not(":nth-child(4)").addClass('input-required');
            $addWebsite.appendTo(".signup__websites").fadeIn();

            if ($('.addExtraWebsite__remove-website-btn').length) {
               $('.addExtraWebsite__remove-website-btn').on('click', function() {
                  if ($(this).parent().find('.validation-input.correct').length > 0) {
                     var $confirmDelete = confirm("Er du sikker på at du vil slette det valgte website?");
                     
                     if ($confirmDelete == true) {
                        $(this).parent().remove();
                        console.log('Slettet!');
                     } else {
                        console.log('Fortryd');
                     }
                  } else {
                     $(this).parent().remove();
                  }
               });
            }
         });
      }

      function validateInput($this) {
         if ($this.hasClass("validated")) {
            var validationType = $this.data("validate"), message = null;

            if ($this.data("validate")) {
               if ($this.is(':checkbox')) {
                  if (!$this.is(':checked')) {
                     changeValidationState($this, "Dette felt er påkrævet");
                     return false;
                  }
               } else {
                  if ($this.val() == null || $this.val().length === 0) {
                     changeValidationState($this, "Dette felt er påkrævet");
                     return false;
                  }
               }
            }

            if (validationType === "email") {
               var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                  if (!regex.test($this.val()))
                     message = "Indtast gyldig e-mail";
            }
            changeValidationState($this, message);
         }
      }

      function changeValidationState($this, message) {
         if (message == null) {
            $this.parent().addClass("correct").removeClass("wrong").children(".wrong-span").remove();
         } else if (message === "remove") {
            $this.parent().removeClass("correct").removeClass("wrong").children(".wrong-span").remove();
         } else {
            $this.parent().removeClass("correct").addClass("wrong");
            var span = $this.siblings("span");
            if (span.length !== 0) span.text(message);
            else $this.parent().append("<span class='wrong-span'>" + message + "</span>");
         }
      }


   return {
        init: init,
        inputValidation : inputValidation
   }

}();