'use strict';
/*
* Novicell master
*  Author: APN modified by DLA
*  Use this file to call functions in other files, on document ready, window.resize etc.
*  ------
* Dependencies: jquery, novicell.js, other files with awesome functions.
*  Usage: $(function () {
*              projectName.slider.heroSlider($('.owl-carousel'));
*         }); // Document ready end
*/

var novicell = novicell || {};
var partnerAds = partnerAds || {};

// Document ready
(function ($) {
    // Only include Novicell functions that you use!
    
    // novicell.cookieInfo.init();
    // novicell.embed.onLoad(); 

    // Call new functions here like this:
    //projectName.slider.heroSlider($('.owl-carousel'));
    svg4everybody(); // Fix SVG spritemap in IE/Edge


    novicell.font.webfont({
        google: { families: ['Open+Sans:400,600,700:latin'] }
    });
    //novicell.font.local("Danni Er Nice");

    /*
    *  Use the following if needed
    */

    // Window load
    // $(window).load(function(e){
    //     // call functions here
    // }); // Window load

    partnerAds.validate.init();
    partnerAds.cvrlookup.init();
    partnerAds.filterList.init();
   

    if ($(window).width() < 768) {
        $('.accordion').on('click', function() {
            $(this).next().slideToggle();
        });
    }

    $('.intro-banner__scroll-down-btn').on('click', function() {
        $('html, body').animate({
            scrollTop: $(".change-business-section").offset().top
        }, 500);
    });

    $('.site-header__login').on('click', function() {
        $(this).parent().next().fadeToggle("fast");
        $(this).toggleClass('login-overlay-active');
    });

    $('.mobile-row__icon').on('click', function() {
        $(this).parent().next().fadeToggle("fast");
        $(this).toggleClass('active');
        $(this).closest("li").siblings().find('.mobile-row-overlay').hide();
        $(this).closest("li").siblings().find('.mobile-row__icon').removeClass('active');
    });

    $(document).mouseup(function(e) 
    {
        var container = $(".login, .site-header__login");

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) 
        {
            $('.login').fadeOut("fast");
            $('.site-header__login').removeClass('login-overlay-active');
        }
    });

    // Window resize (debounced)
    $(window).smartresize(function(e){
        // novicell.embed.onResize();
    }); // Window resize

    // Window scroll (debounced)
    $(window).smartscroll(function (e) {
        // call functions here
        // novicell.embed.onScroll();
    }); // Window scroll

})(jQuery); // Document ready end


